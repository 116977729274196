import { useBoolean, Box } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import React, { ReactElement, useState } from "react";
import { createEntity } from "../../api/common";
import { AuthModal } from "../../components/auth/AuthModal";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { MonsterForm } from "../../components/create_homebrew/monster/MonsterForm";
import { uploadMonsterPdf } from "../../components/create_homebrew/pdfgeneration/MonsterPdf";
import { Break } from "../../components/styled/Break";
import { Title } from "../../components/styled/Text";
import { COLLECTIONS } from "../../constants/General";
import { MonsterEntity, defaultMonsterEntity } from "../../constants/Monster";
import { useAlertContext } from "../../context/Alert";
import { useApiClient } from "../../context/ApiClient";
import { useAuthState } from "../../context/AuthState";
import { generateS3Key } from "../../Utils";

export default function Monster(): ReactElement {
  const [title, setTitle] = useState("Monster Maker");
  const [showSignUpForm, setShowSignUpForm] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();
  const authState = useAuthState();
  const apiClient = useApiClient().getClient();
  const s3Directory = COLLECTIONS.Monsters;
  const { alert, addAlert } = useAlertContext();

  const onSubmit = async (values: MonsterEntity) => {
    if (!authState.loggedIn) {
      setShowSignUpForm.on();
    } else {
      setIsLoading.on();
      const userId = authState.id || "";
      const fileS3Key = generateS3Key(userId, values.name, s3Directory);
      try {
        await uploadMonsterPdf(values, authState, fileS3Key);
        await createEntity(apiClient, authState.id || "", fileS3Key, COLLECTIONS.Monsters, values);
        navigate("/saved/monster");
      } catch (error) {
        setIsLoading.off();
        addAlert(false, `Something went wrong with monster generation. ${error}`);
      }
    }
  };

  return (
    <Box backgroundColor="monster.background" minH="100vh">
      <AuthModal isOpen={showSignUpForm} onClose={setShowSignUpForm.off} startSignUp={true} />
      {isLoading ? (
        <div />
      ) : (
        <div>
          <MyNavbar />
          {alert}
          <Break height="5vh" />
          <Box bg="white" m="0 5vw 0 5vw" p="0 3vw 0 3vw" borderRadius="xl">
            <Title text={title} fontSize="2.3rem" />
            <MonsterForm setTitle={setTitle} onSubmit={onSubmit} monsterEntity={defaultMonsterEntity} />
          </Box>
          <Break height="5vh" />
        </div>
      )}
    </Box>
  );
}
